import { createRouter, createWebHistory } from 'vue-router';

// Cek keberadaan data pengguna di localStorage
// const userData = localStorage.getItem('token') == null ? false : true;

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/dashboard/AdminContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'login',
    path: '/auth/login',
    component: () => import('@/views/login/AdminPage.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman login
      if (localStorage.getItem('token') != null) {
        next('/'); // Alihkan ke halaman beranda jika sudah masuk
      } else {
        next(); // Lanjutkan ke halaman login jika belum masuk
      }
    },
  },
  {
    name: 'user master',
    path: '/users',
    component: () => import('@/views/users/UsersContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'invite users',
    path: '/invite_users',
    component: () => import('@/views/registration/invite/InviteContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'registered users',
    path: '/registered_users',
    component: () =>
      import('@/views/registration/registered/RegisteredContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'app new',
    path: '/app-new',
    component: () => import('@/views/app master/app new/AppNewContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'app country',
    path: '/app-country',
    component: () =>
      import(
        '@/views/app master/app country city/country/AppCountryContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'app city',
    path: '/app-city',
    component: () =>
      import('@/views/app master/app country city/city/AppCityContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'industry master',
    path: '/industry_master',
    component: () =>
      import('@/views/industry master/industry/IndustryMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'sub industry master',
    path: '/sub_industry_master',
    component: () =>
      import(
        '@/views/industry master/sub industry/SubIndustryMasterContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'position master',
    path: '/position_master',
    component: () =>
      import('@/views/position master/PositionMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'city master',
    path: '/city-master',
    component: () =>
      import('@/views/manage countries/city master/CityMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'town master',
    path: '/town-master',
    component: () =>
      import('@/views/manage countries/town master/TownMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'country master',
    path: '/country-master',
    component: () =>
      import(
        '@/views/manage countries/country master/CountryMasterContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'zone master',
    path: '/zone-master',
    component: () =>
      import('@/views/manage countries/zone master/ZoneMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'healthcare settings',
    path: '/healthcare-settings',
    component: () =>
      import(
        '@/views/the syringe/healthcare settings/HealthcareSettingsContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'skills group',
    path: '/skills-group',
    component: () =>
      import('@/views/skills master/skills group/SkillsGroupContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'primary skills',
    path: '/primary-skills',
    component: () =>
      import('@/views/skills master/primary skills/PrimarySkillsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'primary skills regurable association',
    path: '/primary-skills/regu-assoc/:id',
    component: () =>
      import(
        '@/views/skills master/primary skills/regu assoc/ReguAssocContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'primary skills regurable association',
    path: '/primary-skills/country/:id',
    component: () =>
      import(
        '@/views/skills master/primary skills/country/CountryContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },

  {
    name: 'jobs master',
    path: '/jobs-master',
    component: () => import('@/views/job master/JobMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'jobs master detail',
    path: '/jobs-master/:id',
    component: () =>
      import('@/views/job master/detail/JobMasterDetailContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'jobs master description',
    path: '/jobs-master/desc/:id',
    component: () =>
      import('@/views/job master/description/JobMasterDescContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'jobs master country',
    path: '/jobs-master/show-in-country/:id',
    component: () => import('@/views/job master/country/CountryContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'Application master',
    path: '/application-master',
    component: () =>
      import('@/views/application master/ApplicationMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'Applicant master',
    path: '/applicant-master',
    component: () =>
      import('@/views/applicant master/ApplicantMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'partner master',
    path: '/partner_master',
    component: () =>
      import('@/views/partner master/PartnerMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'partner master main info',
    path: '/partner_master/main-info/:id',
    component: () =>
      import('@/views/partner master/main info/MainInfoContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'partner master contacts',
    path: '/partner_master/contacts/:id',
    component: () =>
      import('@/views/partner master/contacts/ContactsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'partner master socials',
    path: '/partner_master/socials/:id',
    component: () =>
      import('@/views/partner master/socials/SocialsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'partner master locations',
    path: '/partner_master/locations/:id',
    component: () =>
      import('@/views/partner master/locations/LocationsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },

  {
    name: 'category master',
    path: '/category_master',
    component: () =>
      import('@/views/category master/CategoryMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'product master',
    path: '/product_master',
    component: () =>
      import('@/views/product master/ProductMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'product range',
    path: '/product_range/:id',
    component: () =>
      import('@/views/product master/range/ProductRangeContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'quantity master',
    path: '/quantity_master',
    component: () =>
      import('@/views/quantity master/QuantityMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'brands master',
    path: '/brands_master',
    component: () =>
      import('@/views/category master/brands master/BrandsMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'qualification master',
    path: '/qualification_master',
    component: () =>
      import('@/views/qualification master/QualificationMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'qualification master skills',
    path: '/qualification_master/skills/:id',
    component: () =>
      import(
        '@/views/qualification master/skills/QualificationSkillsContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'course master',
    path: '/course_master',
    component: () => import('@/views/course master/CourseMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'university master',
    path: '/university_master',
    component: () =>
      import('@/views/course master/university/UniversityMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'course master registrable',
    path: '/course_master/registrable/:id',
    component: () =>
      import(
        '@/views/course master/registrable/CourseRegistrableContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'walls master',
    path: '/walls_master',
    component: () => import('@/views/walls master/WallsMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'property types',
    path: '/property_types',
    component: () =>
      import('@/views/walls master/property types/PropertyTypesContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'property developers',
    path: '/property_developers',
    component: () =>
      import(
        '@/views/walls master/property developers/PropertyDevelopersContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'property developments',
    path: '/property_developments',
    component: () =>
      import(
        '@/views/walls master/property developments/PropertyDevelopmentsContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'property developments main info',
    path: '/property_developments/main-info/:id',
    component: () =>
      import(
        '@/views/walls master/property developments/main info/PropertyDevelopmentsMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'construction master main info',
    path: '/construction_master/main_info/:id',
    component: () =>
      import(
        '@/views/walls master/construction master/main info/ConstructionMasterMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'main categories',
    path: '/main_categories',
    component: () =>
      import(
        '@/views/walls master/main categories/MainCategoriesContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'construction category',
    path: '/construction_category',
    component: () =>
      import(
        '@/views/walls master/construction category/ConstructionCategoryContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'construction master',
    path: '/construction_master',
    component: () =>
      import('@/views/walls master/construction master/ConstructionMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'building type',
    path: '/building_type',
    component: () =>
      import('@/views/walls master/building type/BuildingTypeContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'facilities',
    path: '/facilities',
    component: () =>
      import('@/views/walls master/facility/FacilityContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'apartment type master',
    path: '/apartment_type_master',
    component: () =>
      import('@/views/walls master/apartment type master/ApartmentTypeMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'development construction',
    path: '/property_developments/development_construction/:id',
    component: () =>
      import(
        '@/views/walls master/property developments/development construction/DevelopmentConstructionContainer.vue'
      ),
      beforeEnter: (to, from, next) => {
        // Pengecekan status login sebelum masuk ke halaman beranda
        if (localStorage.getItem('token') == null) {
          next('/auth/login'); // Alihkan ke halaman login jika belum masuk
        } else {
          next(); // Lanjutkan ke halaman beranda jika sudah masuk
        }
      }, 
  },
  {
    name: 'construction facility',
    path: '/construction_master/facility/:id',
    component: () =>
      import(
        '@/views/walls master/construction master/construction facility/ConstructionFacilityContainer.vue'
      ),
      beforeEnter: (to, from, next) => {
        // Pengecekan status login sebelum masuk ke halaman beranda
        if (localStorage.getItem('token') == null) {
          next('/auth/login'); // Alihkan ke halaman login jika belum masuk
        } else {
          next(); // Lanjutkan ke halaman beranda jika sudah masuk
        }
      }, 
  },
  {
    name: 'construction apartment',
    path: '/construction_master/apartment/:id',
    component: () =>
      import(
        '@/views/walls master/construction master/construction apartment/ConstructionApartmentContainer.vue'
      ),
      beforeEnter: (to, from, next) => {
        // Pengecekan status login sebelum masuk ke halaman beranda
        if (localStorage.getItem('token') == null) {
          next('/auth/login'); // Alihkan ke halaman login jika belum masuk
        } else {
          next(); // Lanjutkan ke halaman beranda jika sudah masuk
        }
      }, 
  },
  {
    name: 'property master',
    path: '/property_master',
    component: () =>
      import(
        '@/views/walls master/property master/PropertyMasterContainer.vue'
      ),
      beforeEnter: (to, from, next) => {
        // Pengecekan status login sebelum masuk ke halaman beranda
        if (localStorage.getItem('token') == null) {
          next('/auth/login'); // Alihkan ke halaman login jika belum masuk
        } else {
          next(); // Lanjutkan ke halaman beranda jika sudah masuk
        }
      }, 
  },
  {
    name: 'property master main info',
    path: '/property_master/main-info/:id',
    component: () =>
      import(
        '@/views/walls master/property master/main info/PropertyMastersMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'agent master',
    path: '/agent_master',
    component: () =>
      import(
        '@/views/walls master/agent master/AgentMasterContainer.vue'
      ),
      beforeEnter: (to, from, next) => {
        // Pengecekan status login sebelum masuk ke halaman beranda
        if (localStorage.getItem('token') == null) {
          next('/auth/login'); // Alihkan ke halaman login jika belum masuk
        } else {
          next(); // Lanjutkan ke halaman beranda jika sudah masuk
        }
      }, 
  },
  {
    name: 'biryani run',
    path: '/biryani-home',
    component: () => import('@/views/biryani run/BiryaniRunContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'main categories',
    path: '/biryani-home/main-categories',
    component: () => import('@/views/biryani run/main categories/MainCategoriesContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'mall master',
    path: '/mall_master',
    component: () => import('@/views/mall master/MallMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'mall levels',
    path: '/mall_master/levels/:id',
    component: () =>
      import('@/views/mall master/mall levels/MallLevelsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'mall services',
    path: '/mall_master/services/:id',
    component: () =>
      import('@/views/mall master/mall services/MallServicesContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'merchants master',
    path: '/merchants_master',
    component: () =>
      import('@/views/mall master/merchants/MerchantsMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage events',
    path: '/manage_events',
    component: () =>
      import('@/views/mall master/manage events/ManageEventsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage events main info',
    path: '/manage_events/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/manage events/main info/EventsMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage parking info',
    path: '/manage_parking_info',
    component: () =>
      import(
        '@/views/mall master/manage parking info/ManageParkingInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage parking info main info',
    path: '/manage_parking_info/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/manage parking info/main info/ParkingInfoMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage parking info services',
    path: '/manage_parking_info/services/:id',
    component: () =>
      import(
        '@/views/mall master/manage parking info/services/ServicesContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage parking info levels',
    path: '/manage_parking_info/levels/:id',
    component: () =>
      import(
        '@/views/mall master/manage parking info/levels/LevelsContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage jobs',
    path: '/manage_jobs',
    component: () =>
      import('@/views/mall master/manage jobs/ManageJobsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage jobs main info',
    path: '/manage_jobs/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/manage jobs/main info/JobsMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage mall promos',
    path: '/manage_mall_promos',
    component: () =>
      import(
        '@/views/mall master/manage mall promos/ManageMallPromosContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage mall promos main info',
    path: '/manage_mall_promos/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/manage mall promos/main info/MallPromosMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage levels',
    path: '/manage_levels',
    component: () =>
      import('@/views/mall master/manage levels/ManageLevelsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'manage services',
    path: '/manage_services',
    component: () =>
      import('@/views/mall master/manage services/ManageServicesContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'outlets master',
    path: '/outlets_master',
    component: () =>
      import('@/views/mall master/outlets/OutletsMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'promotions master',
    path: '/promotions_master',
    component: () =>
      import('@/views/mall master/promotions/PromotionsMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'promotions master main info',
    path: '/promotions_master/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/promotions/main info/PromotionsMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'promotions master outlets',
    path: '/promotions_master/outlets/:id_promo/:id_merchant',
    component: () =>
      import('@/views/mall master/promotions/outlets/OutletsContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'tag header',
    path: '/tag-header',
    component: () =>
      import('@/views/mall master/tag header/TagHeaderContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'tag master',
    path: '/tag-master',
    component: () =>
      import('@/views/mall master/tag master/TagMasterContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'displayed banners',
    path: '/displayed-banners',
    component: () =>
      import(
        '@/views/mall master/displayed banners/DisplayedBannersContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'displayed banners main info',
    path: '/displayed-banners/main-info/:id',
    component: () =>
      import(
        '@/views/mall master/displayed banners/main info/BannerMainInfoContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'displayed banners outlets',
    path: '/displayed-banners/outlets/:id_banner/:id_merchant',
    component: () =>
      import(
        '@/views/mall master/displayed banners/outlets/OutletsContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'mall country',
    path: '/mall-country',
    component: () =>
      import(
        '@/views/mall master/countryCity/country/MallCountryContainer.vue'
      ),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },
  {
    name: 'mall city',
    path: '/mall-city',
    component: () =>
      import('@/views/mall master/countryCity/city/MallCityContainer.vue'),
    beforeEnter: (to, from, next) => {
      // Pengecekan status login sebelum masuk ke halaman beranda
      if (localStorage.getItem('token') == null) {
        next('/auth/login'); // Alihkan ke halaman login jika belum masuk
      } else {
        next(); // Lanjutkan ke halaman beranda jika sudah masuk
      }
    },
  },

  {
    path: '/*', // Rute ini akan menangkap semua rute yang tidak cocok dengan rute lainnya
    redirect: '/', // Alihkan ke halaman dashboard
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
